import React, { useState } from 'react';
import { filter } from 'lodash';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';

import {
  TablePagination,
  Stack,
  Button,
  Tooltip,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import InfoIcon from '@mui/icons-material/Info';
import TableListHead from '../../../layouts/batch/TableListHead';
import NewLeaderboardBody from './NewLeaderboardBody';
import { ExportJsonCsv } from 'react-export-json-csv';
import { partnerIds } from '../../../../constants/common';

function NewLeaderboardHeader({ data, batchData }) {
  const isDeviare = batchData.partner === partnerIds.deviare;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('belongScore');
  const [userStatus, setUserStatus] = useState('All');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const activeUser = data.filter((user) => {
    if (userStatus === 'All') {
      return true;
    }
    return userStatus === user.status;
  });

  let rows = activeUser.map((el) => {
    return {
      name: el.name,
      email: el.email,
      belongScore: el.belongScore || 0,
      profileStrength: el.profileStrength || 0,
      aptitudeScore: el.aptitudeAttempts?.percentage || 0,
      vgaScore: el.vgaData?.vgaScore || 0,
      attendanceRate: el.vgaData?.attendanceRate || 0,
      submissionRate: el.vgaData?.submissionRate || 0,
      quizScore: el.vgaData?.quizScore || 0,
      projectTaskScore: el.vgaData?.projectTaskScore || 0,
      pitchPresentation: el.vgaData?.pitchPresentation || 0,
      completionStatus: el.completionStatus || '-',
    };
  });

  const TABLE_HEAD = [
    { id: 'name', label: 'Name', alignRight: false },
    {
      id: 'belongScore',
      label: `${isDeviare ? 'Employability' : 'Belong'} Score`,
      alignRight: false,
    },
    { id: 'profileStrength', label: 'Profile Strength', alignRight: false },
    { id: 'aptitudeScore', label: 'Aptitude Score', alignRight: false },
    { id: 'vgaScore', label: 'VGA Score', alignRight: false },
    { id: 'attendanceRate', label: 'Attendance Rate', alignRight: false },
    { id: 'submissionRate', label: 'Submission Rate', alignRight: false },
    { id: 'quizScore', label: 'Quiz Score', alignRight: false },
    { id: 'projectTaskScore', label: 'Project Score', alignRight: false },
    { id: 'pitchPresentation', label: 'Pitch Presentation', alignRight: false },
    { id: 'completionStatus', label: 'Certificate Status', alignRight: false },
  ];
  const headers = [
    { key: 'name', name: 'Name' },
    { key: 'email', name: 'Email' },
    { key: 'belongScore', name: 'Belong Score' },
    { key: 'profileStrength', name: 'Profile Strength /15' },
    { key: 'aptitudeScore', name: 'Aptitude Score' },
    { key: 'vgaScore', name: 'VGA Score' },
    { key: 'attendanceRate', name: 'Attendance Rate' },
    { key: 'submissionRate', name: 'Submission Rate' },
    { key: 'quizScore', name: 'Quiz Score' },
    { key: 'projectTaskScore', name: 'Project Score' },
    { key: 'pitchPresentation', name: 'Pitch Presentation' },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query) {
      return filter(
        array,
        (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    }
    return stabilizedThis.map((el) => el[0]);
  }

  const filteredUsers = applySortFilter(rows, getComparator(order, orderBy));

  return (
    <>
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        spacing={1}
      >
        <FormControl sx={{ marginInline: 4, width: 200 }} size='small'>
          <InputLabel id='demo-simple-select-label'>User Status</InputLabel>
          <Select
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            value={userStatus}
            label='userStatus'
            onChange={(e) => setUserStatus(e.target.value)}
          >
            <MenuItem value='All'>All</MenuItem>
            <MenuItem value='Active'>Active</MenuItem>
            <MenuItem value='Inactive'>Inactive </MenuItem>
          </Select>
        </FormControl>
        <Stack
          direction='row'
          justifyContent='justify-end'
          alignItems='center'
          spacing={1}
        >
          <Tooltip
            title={
              <>
                <Typography>
                  {isDeviare ? 'Employability' : 'Belong'} Score :
                </Typography>
                <Typography>Profile Strength: 15% </Typography>
                <Typography>Aptitude Score: 40% </Typography>
                <Typography>VGA Score: 45% </Typography>
                <Typography ml={4}>Attendance Rate: 10% </Typography>
                <Typography ml={4}>Submission Rate: 10% </Typography>
                <Typography ml={4}>Quiz Score: 30% </Typography>
                <Typography ml={4}>Project Score: 50% </Typography>
                {/* <Typography ml={4}>Pitch Presentation: 10% </Typography> */}
              </>
            }
          >
            <InfoIcon />
          </Tooltip>

          <ExportJsonCsv headers={headers} items={filteredUsers}>
            <Button variant='outlined'>Leaderboard Details</Button>
          </ExportJsonCsv>
        </Stack>
      </Stack>
      <TableContainer sx={{ maxHeight: 500 }} component={Paper}>
        <Table
          stickyHeader
          sx={{ minWidth: 650, shadow: 2, padding: '6px' }}
          aria-label='table'
          size='small'
        >
          <TableListHead
            order={order}
            orderBy={orderBy}
            headLabel={TABLE_HEAD}
            rowCount={rows.length}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {filteredUsers
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <NewLeaderboardBody key={index} row={row} />
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 50, 100]}
          component='div'
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
}

export default NewLeaderboardHeader;
