import React, { useState } from 'react';
import TableCell from '@mui/material/TableCell';
import { TableRow } from '@mui/material';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

function DeviareLeaderboardbody({ row, isTimeSheet }) {
  const [open, setOpen] = useState(false);

  const useStyles = makeStyles((theme) => ({
    nameColor: {
      color: open ? 'blue' : '',
    },
  }));

  const classes = useStyles();

  return (
    <>
      <TableRow
        key={row._id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell component='th' scope='row' padding='none'>
          <Typography
            variant='subtitle2'
            className={classes.nameColor}
            onClick={() => setOpen(!open)}
            sx={{ cursor: 'pointer' }}
            noWrap
          >
            {row.name}
          </Typography>
        </TableCell>
        <TableCell
          align='center'
          sx={{
            color: row.employabilityScore < 72.5 ? 'error.main' : 'inherit',
          }}
        >
          {row.employabilityScore}
        </TableCell>
        <TableCell
          align='center'
          sx={{ color: row.attendanceRate < 80 ? 'error.main' : 'inherit' }}
        >
          {row.attendanceRate.toFixed(2)} %{' '}
        </TableCell>
        <TableCell
          align='center'
          sx={{ color: row.submissionRate < 70 ? 'error.main' : 'inherit' }}
        >
          {row.submissionRate} %
        </TableCell>

        <TableCell
          align='center'
          sx={{ color: row.projectTaskScore < 70 ? 'error.main' : 'inherit' }}
        >
          {' '}
          {row.projectTaskScore} %{' '}
        </TableCell>
        <TableCell
          align='center'
          sx={{ color: row.pitchPresentation < 70 ? 'error.main' : 'inherit' }}
        >
          {' '}
          {row.pitchPresentation} %{' '}
        </TableCell>
        <TableCell
          align='center'
          sx={{ color: row.vgaQuiz < 70 ? 'error.main' : 'inherit' }}
        >
          {' '}
          {row.vgaQuiz}%{' '}
        </TableCell>
        <TableCell
          align='center'
          sx={{ color: row.aptitudeQuiz < 70 ? 'error.main' : 'inherit' }}
        >
          {' '}
          {row.aptitudeQuiz} %{' '}
        </TableCell>
        <TableCell align='center'>
          {row.completionStatus === 'Not Applicable'
            ? '-'
            : row.completionStatus}
        </TableCell>

        {isTimeSheet && (
          <TableCell
            align='center'
            sx={{
              color: row.timeCommitmentRate < 70 ? 'error.main' : 'inherit',
            }}
          >
            {' '}
            {row.timeCommitmentRate.toFixed(2)} %{' '}
          </TableCell>
        )}
      </TableRow>
    </>
  );
}

export default DeviareLeaderboardbody;
