import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { format } from 'date-fns';
import { Typography, Chip } from '@mui/material';
import TableListHead from '../../../layouts/batch/TableListHead';
import { applySortFilter, getComparator } from '../../../helper/TableShort';

export default function EventList({ attendance, allEvents }) {
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('dateTime');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const TABLE_HEAD = [
    {
      id: 'title',
      label: 'Topic',
      align: 'left',
    },
    {
      id: 'dateTime',
      label: 'Scheduled On',
      align: 'left',
    },
    {
      id: 'attend',
      label: 'Participants',
      align: 'left',
    },
    {
      id: 'attendanceRate',
      numeric: false,
      disablePadding: true,
      label: 'Attendance Rate',
      align: 'left',
    },
  ];

  const activeUser = attendance.filter((user) => user.status === 'Active');

  const myEventData = allEvents.map((event) => {
    const eventAttend = activeUser.filter((user) => {
      const userEvent = user.events.filter((userOneEvent) => {
        return (
          userOneEvent._id === event._id &&
          userOneEvent.attendance &&
          userOneEvent.attendance.status
        );
      }).length;
      return userEvent;
    }).length;

    const eligibleUser = activeUser.filter((user) => {
      const userEvent = user.events.filter((userOneEvent) => {
        return userOneEvent._id === event._id;
      }).length;
      return userEvent;
    }).length;
    const attendanceRate = eligibleUser
      ? Math.round((eventAttend * 100) / eligibleUser)
      : 0;
    return {
      ...event,
      attend: eventAttend,
      total: eligibleUser,
      attendanceRate: attendanceRate,
    };
  });

  const filteredUsers = applySortFilter(
    myEventData,
    getComparator(order, orderBy)
  );
  return (
    <TableContainer sx={{ maxHeight: 450 }}>
      <Table
        sx={{ minWidth: 650, borderRadius: 2, shadow: 2 }}
        stickyHeader
        aria-label='sticky table'
        size='small'
      >
        <TableListHead
          order={order}
          orderBy={orderBy}
          headLabel={TABLE_HEAD}
          rowCount={myEventData.length}
          onRequestSort={handleRequestSort}
        />

        <TableBody>
          {filteredUsers.map((event, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align='left'>{event.title}</TableCell>
              <TableCell align='center'>
                {format(new Date(event.dateTime), 'do LLL yy')}
              </TableCell>
              <TableCell align='center'>{event.attend}</TableCell>
              <TableCell align='center'>
                {event.attendanceUploaded ? (
                  event.total ? (
                    <Typography>
                      {Math.round((event.attend * 100) / event.total)} %
                    </Typography>
                  ) : (
                    <Chip label='No eligible user' size='small' />
                  )
                ) : (
                  <Chip
                    variant='outlined'
                    color='error'
                    size='small'
                    label='Attendance not added'
                  />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
