import { Navigate, useRoutes } from 'react-router-dom';
import { useEffect } from 'react';
// layouts
import DashboardLayout from './components/layouts/dashboard';
import LogoOnlyLayout from './components/layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import NotFound from './pages/Page404';
import DashboardApp from './pages/DashboardApp';
import BatchOverview from './pages/BatchOverview';
import CapstoneOverview from './pages/CapstoneOverview';
import Team from './pages/Team';
// import UsersTable from './pages/UsersTable';
// import Registrations from './pages/Registrations';

import { Auth, Amplify } from 'aws-amplify';
import { connect } from 'react-redux';
import { fetchPartnerDetails, signIn } from './redux';
import AllUsers from './components/users/AllUsers';

// ----------------------------------------------------------------------

Amplify.configure({
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID

    // REQUIRED - Amazon Cognito Region
    region: 'us-east-2',

    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: 'us-east-2_h5OexVjb0',

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: '20738icdfn538kieuetqrajoj2',

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,
    // (optional) - Hosted UI configuration
    oauth: {
      domain: 'belong.auth.us-east-2.amazoncognito.com',
      scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: 'https://dashboard.belong.education',
      redirectSignOut: 'https://platform.deviare.co.za',
      clientId: '20738icdfn538kieuetqrajoj2',
      responseType: 'token',
    },
  },
});

const Router = ({ fetchPartnerDetails, authStatus, signIn }) => {
  useEffect(() => {
    Auth.currentAuthenticatedUser({
      bypassCache: true, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then((user) => {
        localStorage.setItem(
          'authorization',
          user.signInUserSession.accessToken.jwtToken
        );
        fetchPartnerDetails();
        signIn();
      })
      .catch(() => {
        console.log('Not signed in');
      });
  }, [fetchPartnerDetails, signIn]);

  return useRoutes([
    {
      path: '/dashboard',
      element: authStatus ? <DashboardLayout /> : <Navigate to='/login' />,
      children: [
        { element: <Navigate to='/dashboard/app' replace /> },
        { path: 'app', element: <DashboardApp /> },
        { path: 'batch', element: <BatchOverview /> },
        { path: 'users', element: <AllUsers /> },
        { path: 'team', element: <Team /> },
        { path: 'capstone', element: <CapstoneOverview /> },
      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to='/login' /> },
        { path: '*', element: <Navigate to='/404' /> },
      ],
    },
    { path: '*', element: <Navigate to='/404' replace /> },
  ]);
};

const mapStateToProps = (state) => {
  return {
    authStatus: state.auth.authStatus,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchPartnerDetails: (token) => dispatch(fetchPartnerDetails(token)),
    signIn: () => dispatch(signIn()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Router);
